import { createBrowserRouter, RouterProvider, createRoutesFromElements, Outlet, Route, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { store } from '../store';
import { Provider } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import { NavBar } from './nav-bar';
import { Protected } from '@ui-kit';
import { ManualQuery } from './manual-query';
import { Login } from './login';
import { ParseCode } from './parse-code';
import { IssueSummary } from './issue-summary';
import { useGetLoading } from '@hooks/selectors';
import { useEffect } from 'react';

const AppLayout = () => {
	const isLoading = useGetLoading();

	const navBarHeight = document.getElementById('nav-container')?.clientHeight || 0;

	return (
		<div style={{ position: 'relative', width: '100%', height: '100vh' }}>
			<div id="nav-container">
				<NavBar />
			</div>

			{isLoading && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: `calc(100% - ${navBarHeight}px)`,
						width: '100%',
						position: 'absolute',
						zIndex: 100,
						backgroundColor: 'aliceblue',
						flex: '1 1 auto',
					}}
				>
					<CircularProgress />
				</Box>
			)}
			<Outlet />
		</div>
	);
};

const Home = () => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate('/issues-summary');
	}, []);
	return null;
};

const routes = createRoutesFromElements(
	<Route element={<AppLayout />}>
		<Route path="/" element={<Home />} />
		<Route
			path="/manual-query"
			element={
				<Protected>
					<ManualQuery />
				</Protected>
			}
		/>
		<Route path="/login" element={<Login />} />
		<Route path="/parse-code" element={<ParseCode />} />
		<Route
			path="/issues-summary"
			element={
				<Protected>
					<IssueSummary />
				</Protected>
			}
		/>
	</Route>
);

const router = createBrowserRouter(routes);

export const App: React.FC = () => {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Provider store={store}>
				<RouterProvider router={router} />
			</Provider>
		</Box>
	);
};
