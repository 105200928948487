import { MenuItem, Typography, IconButton, AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@store';

export const NavBar = () => {
	const navigate = useNavigate();
	const user = useSelector((state: RootState) => state.general.user);

	const LoginItem = user ? (
		<Typography textAlign="center" color="orange">
			{user.name}
		</Typography>
	) : (
		<MenuItem onClick={() => navigate('/login')}>
			<Typography textAlign="center">Login</Typography>
		</MenuItem>
	);
	return (
		<AppBar position="static">
			<Toolbar>
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }} color="orange">
					Noldor Jira Integration
				</Typography>
				<MenuItem onClick={() => navigate('/issues-summary')} sx={{ marginRight: '20px' }}>
					<Typography textAlign="center">Issues Summary</Typography>
				</MenuItem>
				<MenuItem onClick={() => navigate('/manual-query')} sx={{ marginRight: '20px' }}>
					<Typography textAlign="center">Manual Query</Typography>
				</MenuItem>
				{LoginItem}
			</Toolbar>
		</AppBar>
	);
};
