import axios from 'axios';
import Papa from 'papaparse';

import { store, dispatch } from '@store';
import { setUser, setIsLoading } from '@slices/general';
import { AuthAPIData, AuthData, DateRange, IssuesSummaryData } from '@types';

const { REACT_APP_API_URL } = process.env;

axios.defaults.baseURL = REACT_APP_API_URL;

export const getAtlassianUrl = async () => {
	const { data } = await axios.post('/get-atlassian-login-url');
	return data;
};

export const sendAtlassianCode = async (code: string) => {
	dispatch(setIsLoading(true));
	const { data } = await axios.post<AuthAPIData>('/parse-code', { code });
	if (data.error) {
		throw new Error(data.error);
	}

	const { token, ...user } = data;
	dispatch(setUser(user as AuthData));
	if (token) {
		axios.defaults.headers.authorization = token;
		localStorage.setItem('jwt', token);
	}

	dispatch(setIsLoading(false));
};

export const getIssuesSummaryData = async (range: DateRange, accountId: string) => {
	try {
		const { data } = await axios.post<IssuesSummaryData>('/get-issues-summary', { range, accountId });
		return data.map((row, index) => ({ id: index + 1, ...row }));
	} catch (err) {
		console.error('Unable to fetch issues summary data');
		return [];
	}
};

export const getValidUser = async () => {
	try {
		const { data } = await axios.post<AuthAPIData>('/is-valid-user');
		if (data.error) {
			throw new Error(data.error);
		}
		store.dispatch(setUser(data as AuthData));
		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
};

type DownloadHoursData = {
	groupedIssues: object[];
	error?: boolean;
};

export const getCsvData = async (jql: string) => {
	const { data } = await axios.post<DownloadHoursData>('/download-hours', { jql });

	const { groupedIssues } = data;
	// TODO: Cater for error
	createAndDownloadCSV(groupedIssues, 'grouped-issues.csv');
};

const createAndDownloadCSV = (json: object[], filename: string) => {
	const csv = Papa.unparse(json);

	const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	const a = document.createElement('a');
	a.href = URL.createObjectURL(blob);
	a.download = filename;

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};
